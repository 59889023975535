#service {
  background-color: #eae9f6;
}
.service-container {
  padding-bottom: 67px;
}
.service-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 45px;
}
.service-lists {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.our-services {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
}
.heading-para {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.our-service-p {
  font-family: poppins;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.our-service-head {
  font-family: patua One;
  font-weight: 500;
  font-size: 32px;
  color: #292c3d;
}
.our-service-para {
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  color: #292c3d;
  line-height: 2;
}
.first-service-list,
.second-service-list {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 45px 0px 45px;
  display: flex;
  flex-direction: column;
  /* gap: 65px; */
  align-items: center;
}
.first-service-list h5::after {
  content: url(../../assets//service//cap-bg.svg);
  position: absolute;
  left: -30px;
  top: -140px;
}
.second-service-list h5::after {
  content: url(../../assets/service/cap-bg.svg);
  position: absolute;
  left: -30px;
  top: -140px;
}
.first-service-list h5,
.second-service-list h5 {
  font-family: patua One;
  font-weight: 500;
  font-size: 18px;
  color: #292c3d;
  padding: 10px;
  position: relative;
}
.cap-img {
  width: 129px;
  height: 129px;
}
.heading-service {
  font-family: patua One;
  font-weight: 500;
  font-size: 28px;
  color: #8b80f8;
  margin-bottom: 11px;
}
.heading-service2 {
  font-family: patua One;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 11px;
}
@media (min-width: 662px) {
    .first-service-list h5::after {
      left: -100px;
    }
    .second-service-list h5::after {
      left: -100px;
    }
    .our-service-para {
      font-size: 17px;
    }
}
@media (min-width: 992px) {
  .service-container {
    padding-bottom: 67px;
  }
  .service-content {
    gap: 25px;
    margin-top: 45px;
  }

  .service-lists {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
  .our-services {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 40px;
  }
  .heading-para {
    font-size: 42px;
  }
  .our-service-head {
    font-size: 45px;
  }
  .first-service-list,
  .second-service-list {
    padding: 25px 65px 0px 65px;
    /* gap: 85px; */
  }
  .first-service-list h5::after {
    left: -50px;
    top: -240px;
  }
  .second-service-list h5::after {
    left: -50px;
    top: -240px;
  }
  .first-service-list h5,
  .second-service-list h5 {
    font-size: 20px;
  }
  .cap-img {
    width: 139px;
    height: 139px;
  }
}
@media (min-width: 1024px) {
  .our-services {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
}
.first-service-list, .second-service-list {
  padding: 35px 35px 0px 35px;
  /* gap: 65px; */
}
.first-service-list h5::after {
  left: -45px;
  top: -150px;
}
.second-service-list h5::after {
  left: -45px;
  top: -150px;
}
}
@media (min-width: 1300px) {
  .service-container {
    padding-bottom: 67px;
  }
  .service-content {
    gap: 35px;
    margin-top: 45px;
  }

  .service-lists {
    display: flex;
    flex-direction: row;
    gap: 35px;
  }
  .our-services {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 65px 40px;
  }
  .heading-para {
    font-size: 42px;
  }
  .our-service-head {
    font-size: 52px;
  }
  .first-service-list,
  .second-service-list {
    padding: 25px 65px 0px 65px;
    /* gap: 85px; */
  }
  .first-service-list h5::after {
    left: -50px;
    top: -150px;
  }
  .second-service-list h5::after {
    left: -50px;
    top: -150px;
  }
  .first-service-list h5,
  .second-service-list h5 {
    font-size: 20px;
  }
  .cap-img {
    width: 139px;
    height: 139px;
  }
}

