#home {
  background-image: url("../../assets/success/success-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f4eff5;
}
.success-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 40px 5px;
}
.success-heading {
  font-family: patua One;
  font-weight: 500;
  color: #796dec;
  font-size: 35px;
}
.success-para {
  font-family: poppins;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
}
.btn {
  background-color: #796dec;
  border: none;
  color: white;
  padding: 14px 32px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 80px;
}
.btn:hover {
  background-color: #a099e3;
  color: white;
}
.arrow-icon {
  margin-left: 10px;
}


.mobile-one {
  margin-top: 0px;
  margin-right: 2px;
  width:15%;
}
.mobile-two {
  margin-right: 2px;
  width: 20%;
}
.mobile-three {
 
  width: 15%;
}
.about-content-container {
  margin-top: 20px;
}
.winscore {
  font-family: poppins;
  font-weight: 600;
  font-size: 18px;
  color: black;
}
.download-app-section {
  margin-bottom: 0px;
  background-color: #FDC251 !important ;
  background: none;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}
.laddy-img {
  /* background-image: url(../../assets/success/laddy-two.png); */
  background-repeat: no-repeat;
  background-size: contain;
  width: 70%;
}
.content-text {
  font-family: Patua One;
  font-weight: 400;
  font-size: 20px;
  color: #292c3d;
}
.text-div {
  text-align: start;
  padding: 20px;

}
.text-div::after {
  display: none;
}

.download-btn {
  padding: 7px 16px;
  font-size: 16px;
  font-family: poppins;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  margin-right: 10px;
  margin-top: 10px;
}
/* .bg-img-container {
  margin-top: 51px;
  background-image: url(./../../assets/success/banner.png);
  background-size:auto;
  background-repeat: none;
 
} */
@media (min-width: 992px) {
  .mobile-one {
    margin-top: 150px;
    margin-right: 28px;
    width: 21%;
  }
  .mobile-two {
    margin-right: 23px;
    width: 26%;
  }
  .mobile-three {
    width: 19%;
  }
  .success-heading {
    font-size: 64px;
  }
  .success-para {
    font-size: 17px;
  }
  .content-text {
    font-size: 35px;
  }
  .text-div {
    text-align: start;
    padding: 60px 50px 40px 60px;
  }
  /* .text-div::after {
    width: 100%; 
    position: absolute;
    left: 485px;
    bottom: -10px;
    content: url(../../assets/success/back-line.png);
    display: none;
  } */
  .laddy-img  {
    width: 125%;
  }
  .download-btn {
    padding: 14px 32px;
    font-size: 20px;
    border-radius: 28px;
    margin-right: 10px;
    margin-top: 20px;
  }
  .download-app-section { 
   
    margin-bottom: 100px;
    /* background: url(../../assets/success/banner.png); */
  }
  
}
@media (min-width: 1300px) {
  .content-text {
    font-size: 35px;
  }
  /* .text-div::after {
    width: 100%; 
    position: absolute;
    left: 485px;
    bottom: -10px;
    content: url(../../assets/success/back-line.png);
    display: block;
  } */
  .laddy-img  {
    width: 100%;
  }
}

/* about */
.about-main-container {
  margin-top: 20px;
}
.laddy-image {
  width: 80%;
}

.about-content-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: start;
}

.about-head {
  font-family: patua one;
  font-weight: 500;
  font-size: 32px;
  color: #796dec;
}
.about-para {
  padding: 10px;
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
}
.iq-cls,
.win-cls {
  font-family: poppins;
  font-weight: 600;
  font-size: 18px;
}
.iq-cls {
  color: #796dec;
}
.win-cls {
  color: #292c3d;
}
@media (min-width: 992px) {
  .success-content {
    gap: 25px;
    margin: 90px 0px 60px 0px;
  }
  .success-para {
    margin: 0px 150px;
  }

  .mobile-one {
    /* margin-top: 230px; */
  }
  /* about */
  .about-main-container {
    margin-top: 100px;
    padding-bottom: 100px;
  }
  .about-content-container {
    gap: 24px;
  }
  .about-para {
    font-size: 17px;
  }
  .laddy-image {
    width: 100%;
   margin-top: 100px;
  }
  .about-head {
    font-size: 52px;
  }
}

@media (min-width: 1300px) {
  .success-para {
    margin: 0px 320px;
  }
  .about-content-container {
    gap: 24px;
    padding: 0px 50px;
  } .download-app-section { 
    margin-top: 100px;
  }
}
