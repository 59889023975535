#service-list {
  background-color: #8b80f8;
  border-radius: 20px;
  margin-top: 25px;
}
.main-service-list {
  padding: 40px 15px;
}
.cap-img-list {
  width: 40%;
  margin-bottom: 21px;
}

.service-list-head {
  font-family: patua One;
  font-weight: 500;
  /* font-size: 34px; */
  color: #ffffff;
  margin-bottom: 11px;
  position: relative;
}
.service-list-head::after {
  position: absolute;
  content: url(./../../../assets/service/service-list-cap-bg.svg);
  top: -225px;
  left: 0px;
  width: 100px;
}
.service-list-para {
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  padding: 10px;
  line-height: 2;
}

.arrow-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
}
.arrow-div {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: white;
}

.service-description-container {
  height: 500px;
  border: 1px white;
  overflow-y: scroll;
}
.service-description-container::-webkit-scrollbar {
  display: none;
}

.service-description-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (min-width: 662px) {
  .service-list-head::after {
    top: -350px;
    left: 0px;
  }
  .service-list-para {
    font-size: 17px;
  }
}
@media (min-width: 992px) {
  #service-list {
    margin-top: 40px;
  }
  .main-service-list {
    padding: 40px 15px;
  }
  .cap-img-list {
    width: 40%;
    margin-bottom: 21px;
  }
  .service-list-head {
    font-size: 28px;
  }
  .service-list-head::after {
    top: -230px;
    left: 0px;
  }
  .service-list-para {
  }
}
@media (min-width: 1024px) {
  .service-list-head::after {
    top: -180px;
    left: -10px;
  }

  .main-service-list {
    padding: 15px;
  }
  .cap-img-list {
    width: 40%;
    margin-bottom: 21px;
    padding-top: 20px;
  }
}
