
.contact-background {
    background-image: url("../../assets/download/download-contact-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.download-right-div {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}
.download-heading {
    font-family: patua One;
    font-weight: 500;
    font-size: 35px;
    color: #796DEC;
    margin-bottom: 25px;
}
.download-para {
    font-family: poppins;
    font-weight: 500;
    font-size: 20px;
    color: #4B5170;
}
.download-span {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #4B5170;
}
.featuters {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-top: 50px;
}
.icon {
    margin-bottom: 10px;
    width: 20px;
}
.icon-content {
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 20px;
}
.contents {
    font-family: poppins;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .5px;
    color: #292C3D;
    text-align: start;
    margin: 0;
}
.mobile-img {
    width: 100%;
}
.download-btn-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.download-head {
    font-family: poppins;
    font-weight: 600;
    font-size: 26px;
    color: #4B5170;
}
.download-buttons {
    display: flex;
    flex-direction: row;
    gap: 14px;
}
.appstore-btn, .playstore-btn {
    background-color: #796dec;
    font-family: poppins;
    font-weight: 600;
    border: none;
    color: white;
    padding: 10px 22px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 80px;
}
.store-one {
    width: 20%;
    margin-bottom: 5px;
}
.store-two {
    width: 18%;
    margin-bottom: 4px;
}
.app-store-icon {
    margin-left: 10px;
    margin-bottom: 3px;
}
.play-store-icon {
    margin-left: 10px;
    margin-bottom: 2px;
}

/* contact */
.contact-p {
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    color: #292C3D;
    margin-top: 50px;  
}
.contact-head {
    font-family: patua One;
    font-weight: 500;
    font-size: 32px;
    color: #796DEC;
    margin-bottom: 24px;
}
.contact-content {
    font-family: poppins;
    font-weight: 500;
    font-size: 16px;
    color: #292C3D;
    margin-bottom: 15px;
    padding: 0px 5px;
}
.contact-image {
    margin-top: 0px ;
    width: 100%;
    /* position: relative; */
}
/* .contact-image::after{
    position: absolute;
    content: url(./../../assets/download/gratuate.png);
 
    left: 0px;
    width: 100px;
} */
 .gratuate {
  display: none;
 }
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}
.contact-input-field {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
   
}
.contact-label {
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    color: #737373 !important;
}
.message-labels {
    display: flex;
    flex-direction: row;
    gap: 90px;
}
.contact-fields {
    padding: 10px 15px;
    width:297px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    margin-top: 8px;
}
.contact-fields:focus{
    border: 2px solid #796DEC;
}
.contact-btn {
background-color: #796dec;
    border: none;
    color: white;
    padding: 14px 32px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 22px;
    margin-bottom: 46px;
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    width: 297px;
}
.error-text {
    color: red;
}


@media(min-width:992px) {

    .download-heading {
        font-size: 50px;
    }
    .download-para {
        font-size: 24px;
    }
    .download-span {
        font-size: 24px;
    }
    .featuters {
        gap: 22px;
        margin-top: 114px;
    }
    .icon-content {
        gap: 15px;
        margin-bottom: 22px;
    }
    .mobile-img {
        width: 100%;
    }
    .download-buttons {
        gap: 24px;
    }
.contents {
    font-size: 16px;
}
.icon {
    margin-bottom: 0px;
    width: 25px;
}
.download-right-div {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: start;
}
.appstore-btn, .playstore-btn {
    padding: 14px 32px;
    font-size: 16px;
    border-radius: 80px;
}
    /* contact */
    .contact-fields {
        padding: 10px 15px;
        width: 497px;
    }
    .contact-content {
        margin-bottom: 42px;
        padding: 0px 30px;
        font-size: 17px;
    }
    .contact-head {
        font-size: 50px;
    }
    .contact-image {
        margin-top: 50px;
    }
    .contact-btn {
        width: 497px;
    }
    .message-labels {
        gap: 280px;
    }
    .gratuate {
        margin-top: 50px;
        margin-right: 490px;
        display: block;
     }
}
