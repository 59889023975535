

.navbar {
  background-color: #F4EFF5;
}

.navbar-expand-lg .navbar-nav .nav-link.active {
  color: #796dec !important;
}


.nav-item a {
  font-family: poppins;
  font-weight: 600;
  font-size: 14px;
  color: #878CA6;
  display: flex;
  gap: 10px;
}
.nav-item a.active {
  color:#796dec;
}
.brand-img {
  width: 50% !important;
}

.navbar-button {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px !important;
  font-family: poppins;
  background-color: #796dec;
  color: #fff;
  border: none;
  border-radius: 10px;
}
.nav-link.active {
  color: #796dec;
}
.navbar-nav {
  align-items: center;
}
.nav-item.active {
  color: #796dec;
}
@media (min-width: 992px) {
  .brand-img {
    width: 60% !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    gap: 36px;
  }

  .navbar-button {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px !important;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 18px !important;
  }
  .nav-item.active {
    text-decoration: underline;
    text-decoration-color: #796dec;
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 6px;
    color: #796dec !important;
  }
  .nav-link:focus, .nav-link:hover {
    color: #796dec;
  }
 
} 
