#login {
    background: #f4eff5;
    height: 100vh;
    width: 100vw;
    position: relative;
  }
  
  .content {
    width: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .brand-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    width: 80px;
    height: 80px;
  }
  
  .business-name {
    font-size: 18px;
    font-weight: 800;
    padding-top: 10px;
    color: #796dec;
  }
  
  .login-card {
    padding: 50px 30px;
    border-radius: 30px;
  }
  
  .login-title {
    font-size: 22px;
    font-weight: 800;
    margin: auto;
  }
  
  .login-btn {
    /* background-color: #ffbe00 !important; */
    background: #796dec !important;
  }
  
  .gZmPOF {
    justify-content: center;
  }
  
  .contact {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: antiquewhite;
    font-size: 12px;
    text-decoration: none;
  }
  
  @media (min-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .content {
      width: 45%;
    }
  }
  
  @media (min-width: 992px) {
    .content {
      width: 25%;
    }
  }
  