#footer {
    background-image: url("../../assets/foooter/footer-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 20px;
  }
.icon-text-container {
  border-bottom:1px solid black;
  border-top:.1px solid black !important;
  border-left:none;
  border-right:none;
}
.border-app {
  border-bottom:0.5px solid black;
}
  .social.media{
    outline-style: solid !important;
  }
  .icons {
    margin-right: 18px;
    margin-top: 20px;
    margin-bottom: 23px;
  }
  .text {
    font-family: poppins;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .foot-link {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .foot-nav-head {
    font-family: poppins;
    font-weight: 700;
    font-size: 21px;
    color: #292C3D;
    margin-bottom: 16px;
  }
  .foot-nav-links {
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    color: #292C3D;
  }
  .foot-map-container {
    width: 276px;
    height: 351px;
    border-radius: 0px;
  }
  .footer-link-map-container {
    display: flex;
    align-items: center;
    padding: 43px 0px;
  }
  .footer-span {
    padding: 28px 0px;
    border-top:1px solid black;
    margin: 0;
    color: #292C3D;
    font-size: 13px;
    font-weight: 600;
    font-family: poppins;
  }
  .map-img {
    width: 40%;
    height: 150px;
    border-radius: 20px;
  }
  .footer-brand-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-brand{
    width: 20%;
  }
  .company-name, .company-address 
  {
    color: #292C3D;
    font-family: poppins;
    font-size: 14px;
  }
  .company-name {
    font-weight: 700;
  }
  .company-address {
    font-weight: 500;
  }
  .footer-span-link {
    margin-left: 5px;
    text-decoration: none;
    color: #292C3D;
    font-size: 13px;
    font-weight: 600;
    font-family: poppins;
  }
  .phone-mail-div {
    display: flex;
    flex-direction: column;
  }
  .social-media-icon-link {
    text-decoration: none;
    color: #292C3D;
    
  }
  .contact-label-footer,.details {
    font-family: poppins;
    color: #292C3D;
    font-size: 14px;
  }
  .contact-label-footer {
    font-weight: 600;
  }
  .details {
    margin-left: 5px;
    text-decoration: none;
    font-weight: 500;
  }
  @media(min-width:992px) {
    #footer {
      padding-top: 40px;
    }
    .foot-link {
      text-align: start;
    }
    .map-img {
      width: 80%;
      height: 300px;
    }
    .border-app {
      border-right:1px solid black;
      border-bottom: 0px;
    }
  }
  
  
 